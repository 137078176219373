const BASE_PATH = '/api/communicationInteractionService';

const communicationInteractionService = {
  parties: `${BASE_PATH}/party`,
  party: (partyId) => `${BASE_PATH}/party/${partyId}`,
  partyInvitations: `${BASE_PATH}/partyinvitation`,
  partyInvitation: (partyInvitationId) => `${BASE_PATH}/partyinvitation/${partyInvitationId}`,
};

export default communicationInteractionService;

export const COMMUNICATION_HUB_URL = `${BASE_PATH}/liveuserhub`;

export const ALL_HUBS_URLS = [COMMUNICATION_HUB_URL];
