const BASE_PATH = '/api/assetService';

const subPath = {
  addressable: `${BASE_PATH}/addressable`,
  catalog: `${BASE_PATH}/catalog`,
  font: `${BASE_PATH}/font`,
  license: `${BASE_PATH}/license`,
  material: `${BASE_PATH}/material`,
  model3d: `${BASE_PATH}/model3d`,
  picture: `${BASE_PATH}/picture`,
  prop: `${BASE_PATH}/prop`,
  shader: `${BASE_PATH}/shader`,
  sky: `${BASE_PATH}/sky`,
  spaceTemplate: `${BASE_PATH}/spacetemplate`,
  structureComponent: `${BASE_PATH}/structureComponent`,
  text3d: `${BASE_PATH}/text3d`,
  texture: `${BASE_PATH}/texture`,
  video: `${BASE_PATH}/video`,
};

const assetManagement = {
  addressables: `${subPath.addressable}`,
  addressable: (id) => `${subPath.addressable}/${id}`,
  catalogs: `${subPath.catalog}`,
  catalog: (id) => `${subPath.addressable}/${id}`,
  fonts: `${subPath.font}`,
  font: (id) => `${subPath.font}/${id}`,
  licenses: `${subPath.license}`,
  license: (id) => `${subPath.license}/${id}`,
  materials: `${subPath.material}`,
  material: (id) => `${subPath.material}/${id}`,
  models3d: `${subPath.model3d}`,
  model3d: (id) => `${subPath.model3d}/${id}`,
  models3dLibrary: `${subPath.model3d}/myLibrary`,
  pictures: `${subPath.picture}`,
  picture: (id) => `${subPath.picture}/${id}`,
  picturesLibrary: `${subPath.picture}/myLibrary`,
  props: `${subPath.prop}`,
  prop: (id) => `${subPath.prop}/${id}`,
  shaders: `${subPath.shader}`,
  shader: (id) => `${subPath.shader}/${id}`,
  skys: `${subPath.sky}`,
  sky: (id) => `${subPath.sky}/${id}`,
  spaceTemplates: `${subPath.spaceTemplate}`,
  spaceTemplate: (id) => `${subPath.spaceTemplate}/${id}`,
  structureComponents: `${subPath.structureComponent}`,
  structureComponent: (id) => `${subPath.structureComponent}/${id}`,
  texts: `${subPath.text3d}`,
  text: (id) => `${subPath.text3d}/${id}`,
  texts3d: `${subPath.text3d}`,
  text3d: (id) => `${subPath.text3d}/${id}`,
  texts3dLibrary: `${subPath.text3d}/myLibrary`,
  textures: `${subPath.texture}`,
  texture: (id) => `${subPath.texture}/${id}`,
  videos: `${subPath.video}`,
  video: (id) => `${subPath.video}/${id}`,
  videoLibrary: `${subPath.video}/myLibrary`,
};

export default assetManagement;
