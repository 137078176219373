export const unityBrokers = {
  settings: 'SettingsBroker',
  character: 'CharacterBroker',
  inspector: 'InspectorBroker',
  scene: 'SceneBroker',
  viewer: 'ViewerBroker',
  editor: {
    core: 'EditorBroker',
    construction: 'ConstructionBroker',
    curation: 'CurationBroker',
    design: 'DesignBroker',
  },
};
