import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './main/components/App';
import reportWebVitals from './reportWebVitals';
import { setupAxiosInterceptors } from './server/interceptors/interceptors';

if (process.env.REACT_APP_MSW) {
  try {
    await import('./server/mocks/browser').then(({ worker }) => worker.start());
  } catch (err) {
    console.error('Could not load MSW with error: ', err); // eslint-disable-line no-console
  }
}

setupAxiosInterceptors();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

// TODO: handle hard refreshing the browser deletes the service worker so we need to reload the page one more
// TODO: however, the solution below creates a problem with incognito mode of browsers, needing further investigation
// if (navigator.serviceWorker.controller) {
//   root.render(
//     <StrictMode>
//       <App />
//     </StrictMode>,
//   );
// } else {
//   window.location.reload();
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
