import { ErrorBoundary, ThemeProvider } from '@artsteps/components';
import AuthProvider from '../../infrastructure/authentication/AuthProvider';
import { TranslationProvider } from '../../infrastructure/translation/TranslationProvider';
import { UserDataProvider } from '../../infrastructure/user-context';
import Main from './Main';

const App = () => (
  <AuthProvider>
    <TranslationProvider language="en">
      <ThemeProvider>
        <UserDataProvider>
          <ErrorBoundary>
            <Main />
          </ErrorBoundary>
        </UserDataProvider>
      </ThemeProvider>
    </TranslationProvider>
  </AuthProvider>
);

export default App;
