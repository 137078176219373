import { createStyles } from '@mantine/core';
import { ProfilePicture } from '@artsteps/components';
import { useDisclosure } from '@mantine/hooks';
import { useUserContext } from '../../../infrastructure/user-context';
import apis from '../../../server/endpoints/apis';
import UserProfileMenu from './UserProfileMenu';

const useStyles = createStyles(() => ({
  profilePicture: {},
}));

const User = () => {
  const { classes } = useStyles();
  const { userData } = useUserContext();

  const [isProfileMenuOpen, { close: closeMenu, open: openMenu }] = useDisclosure(false);

  return (
    <>
      <ProfilePicture
        className={classes.profilePicture}
        size="40px"
        name={userData?.name}
        image={apis.fileManagement({ id: userData?.profilePicture })}
        onClick={openMenu}
      />
      <UserProfileMenu isOpen={isProfileMenuOpen} onClose={closeMenu} user={userData} />
    </>
  );
};

export default User;
