import { createStyles } from '@mantine/core';
import { useOidc } from '@axa-fr/react-oidc';
import { Button } from '@artsteps/components';
import { useTranslation } from '../../../infrastructure/translation/TranslationProvider';
import NotificationsButton from '../notifications/NotificationsButton';
import User from './User';

const useStyles = createStyles(() => ({
  wrapper: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
}));

const UserSection = () => {
  const { isAuthenticated, login } = useOidc();
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      {isAuthenticated ? (
        <>
          <NotificationsButton />
          <User />
        </>
      ) : (
        <Button label={t('Menu.Button.LogIn')} onClick={() => login()} />
      )}
    </div>
  );
};

export default UserSection;
