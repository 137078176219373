import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import { Badge, Icon } from '@artsteps/components';

const useStyles = createStyles(() => ({}));

const NotificationsButton = ({ notifications }) => {
  const { classes, theme } = useStyles();

  return (
    <Badge content={notifications?.length}>
      <Icon
        id="notifications"
        className={classes.icon}
        color={theme.colors.surfaces[1]}
        size="24px"
      />
    </Badge>
  );
};

NotificationsButton.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
};

export default NotificationsButton;
