import PropTypes from 'prop-types';
import { OidcProvider } from '@axa-fr/react-oidc';
import apis from '../../server/endpoints/apis';
import SessionLost from './components/SessionLost';
import UnthemedLoadingScreen from './components/UnthemedLoadingScreen';

const configuration = {
  authority: apis.keycloak,
  client_id: 'react-app-dev',
  logout_redirect_uri: window.location.origin,
  monitor_session: true,
  redirect_uri: `${window.location.origin}/authentication/callback`,
  scope: 'openid profile email',
  service_worker_relative_url: process.env.REACT_APP_MSW ? '' : '/OidcServiceWorker.js',
  service_worker_only: !process.env.REACT_APP_MSW,
};

// TODO: Handle authentication errors through custom components commented out below
const AuthProvider = ({ children }) => (
  <OidcProvider
    configuration={configuration}
    callbackSuccessComponent={UnthemedLoadingScreen}
    callbackErrorComponent={UnthemedLoadingScreen}
    authenticatingComponent={UnthemedLoadingScreen}
    loadingComponent={UnthemedLoadingScreen}
    // serviceWorkerNotSupportedComponent={Override}
    // authenticatingErrorComponent={Override}
    sessionLostComponent={SessionLost}
  >
    {children}
  </OidcProvider>
);

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
