import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import {
  List,
  ProfileMenuContainer,
  ProfileMenuDrawer,
  ProfilePicture,
  useBreakpoints,
} from '@artsteps/components';
import routes from '../../../server/endpoints/routes';
import apis from '../../../server/endpoints/apis';
import DrawerText from './DrawerText';
import useMenuItems from './useMenuItems';

const useStyles = createStyles((theme) => ({
  drawer: {
    background: `${theme.colors.surfaces[4]} 0% 0% no-repeat padding-box`,
    backdropFilter: 'blur(50px)',
  },
  mainContent: {
    flexGrow: 1,
    marginTop: '32px',
  },
  mainMenuItem: {
    fontSize: '22px',
    lineHeight: '26px',
  },
  secondaryMenuItem: {
    fontSize: '16px',
    lineHeight: '22px',
  },
}));

const UserProfileMenu = ({ isOpen, onClose, user = {} }) => {
  const { classes, theme } = useStyles();
  const { mainMenuItems, bottomMenuItems } = useMenuItems(user?.userId);
  const navigate = useNavigate();

  const {
    is: { sm: isSm },
    largerThan: { sm: largerThanSm },
  } = useBreakpoints(theme);

  const size = useMemo(() => {
    if (largerThanSm) {
      return '400px';
    }
    if (isSm) {
      return '50%';
    }
    return '100%';
  }, [isSm, largerThanSm]);

  return (
    <ProfileMenuDrawer isOpen={isOpen} size={size} className={classes.drawer}>
      <ProfileMenuContainer onCloseProfileDrawer={onClose}>
        <ProfilePicture
          name={user.name}
          image={apis.fileManagement({ id: user.profilePicture })}
          size="120px"
          shouldDisplayName
          labelPlacement="bottom-end"
          labelStyle={{
            color: theme.colors.typography[6],
            fontSize: '32px',
            fontWeight: '900',
          }}
          onClick={() => {
            navigate(routes.PROFILE);
            onClose?.();
          }}
        />
        <div className={classes.mainContent}>
          <List listAlignment="start" itemsAlignment="right" shouldDisableDefault>
            {mainMenuItems.map(({ label, to }) => (
              <List.Item key={label}>
                <DrawerText className={classes.mainMenuItem} onClick={onClose} to={to}>
                  {label}
                </DrawerText>
              </List.Item>
            ))}
          </List>
        </div>
        <List listAlignment="end" itemsAlignment="right" shouldDisableDefault>
          {bottomMenuItems.map(({ label, ...rest }) => (
            <List.Item key={label}>
              <DrawerText className={classes.secondaryMenuItem} {...rest}>
                {label}
              </DrawerText>
            </List.Item>
          ))}
        </List>
      </ProfileMenuContainer>
    </ProfileMenuDrawer>
  );
};

UserProfileMenu.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
};

export default UserProfileMenu;
