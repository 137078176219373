import { URL_ORIGIN } from '../../../constants/url';

const BASE_PATH = '/api/fileService';

const fileManagement = ({ path, storage, id, thumbnail }) => {
  if (path && storage) {
    return `${BASE_PATH}/file`;
  }

  if (id) {
    return `${URL_ORIGIN}${BASE_PATH}/file/${id}`;
  }

  if (thumbnail) {
    return `${URL_ORIGIN}${BASE_PATH}/thumbnail/${thumbnail}`;
  }

  return null;
};

export default fileManagement;
