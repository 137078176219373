/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

const useAxios = ({ method, url, body, headers, onSuccess, onError }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [status, setStatus] = useState();

  useEffect(() => {
    if (url) {
      axios({
        method,
        url,
        headers,
        params: method.toUpperCase() === 'GET' && body,
        data: method.toUpperCase() !== 'GET' && body,
      })
        .then((response) => {
          setLoading(false);
          setStatus(response.status);
          setData(response.data);

          if (typeof onSuccess === 'function') {
            onSuccess(response.data, response.headers, response.status);
          }
        })
        .catch((err) => {
          setLoading(false);
          setStatus(err.response?.status);
          setError(err);

          if (typeof onError === 'function') {
            onError(err);
          }
        });
    }
  }, [method, url, JSON.stringify(body), headers]);

  return { data, loading, error, status };
};

export const useAxiosCallback = ({ method, url, headers }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [status, setStatus] = useState();

  const executeRequest = useCallback(
    ({ instanceUrl, body, onSuccess, onError } = {}) => {
      setLoading(true);

      axios({
        method,
        url: instanceUrl || url,
        headers,
        params: method.toUpperCase() === 'GET' && body,
        data: method.toUpperCase() !== 'GET' && body,
      })
        .then((response) => {
          setLoading(false);
          setStatus(response.status);
          setData(response.data);

          if (typeof onSuccess === 'function') {
            onSuccess(response.data, response.headers, response.status);
          }
        })
        .catch((err) => {
          setLoading(false);
          setStatus(err.response?.status);
          setError(err);

          if (typeof onError === 'function') {
            onError(err);
          }
        });
    },
    [method, url, headers],
  );

  return { data, executeRequest, loading, error, status };
};

export default useAxios;
