import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import { ModelDropzone as ArtFormModelDropzone } from '@artsteps/form';
import { useFormContext } from 'react-hook-form';
import UnityInspector from '../unity-inspector/components/UnityInspector';
import { handleDropzoneDroppedFiles } from './utils/filesManipulation';
import { convertFileFromBase64 } from './utils/convertFileFromBase64';
import { exportedModelFileName, exportedThumbnailFileName } from './utils/exportedModelFileNames';

const useStyles = createStyles(() => ({
  previewContainer: {
    padding: '24px',
    gap: '16px',
  },
  inspectorWrapper: {
    flexGrow: 1,
  },
}));

const ModelDropzone = ({
  classNames = {},
  errorMessage,
  maxFileSize = 20 * 1024 ** 2,
  mimeTypes = { 'text/plain': ['.glb', '.obj', '.mtl'], 'image/*': [] },
  urlInputName,
  fileInputName,
  imposterInputName,
  isRequired,
  onInspectorReady,
  onModelExported,
  onThumbnailExported,
  placeholder,
  shouldActivateOnClick,
  shouldDisplayFilePills,
}) => {
  const { classes, cx } = useStyles();
  const { setValue, getValues } = useFormContext();
  const [shouldPillsBeDisplayed, setShouldPillsBeDisplayed] = useState(false);
  const [modelSrc, setModelSrc] = useState();

  const dropzoneStyles = {
    dropzoneRoot: cx(classes.dropzoneRoot, classNames.dropzoneRoot),
    dropzoneInner: cx(classes.dropzoneInner, classNames.dropzoneInner),
    previewContainer: cx(classes.previewContainer, classNames.previewContainer),
    pill: cx(classes.pill, classNames.pill),
    pillsContainer: cx(classes.pillsContainer, classNames.pillsContainer),
  };

  const handleChange = async (uploadedFiles) => {
    const edittedFile = await handleDropzoneDroppedFiles(uploadedFiles);

    setValue(fileInputName, edittedFile);
  };

  const handleRemove = () => {
    setShouldPillsBeDisplayed(false);
  };

  const handleInspectorReady = useCallback(() => {
    const file = getValues(fileInputName);
    const existingFileUrl = getValues(urlInputName);

    if (file) {
      const url = URL.createObjectURL(file);
      const fileName = file.name;

      setModelSrc(`${url}#file=${fileName}`);
    } else if (existingFileUrl) {
      setModelSrc(existingFileUrl);
    }

    if (typeof onInspectorReady === 'function') {
      onInspectorReady();
    }
  }, [fileInputName, getValues, onInspectorReady, urlInputName]);

  const handleModelReady = useCallback(
    (/* modelAnimations */) => {
      setShouldPillsBeDisplayed(shouldDisplayFilePills);
      // TODO: - Handle animations (status.ModelAnimations)
    },
    [shouldDisplayFilePills],
  );

  const handleModelFailed = useCallback(() => {
    // TODO
  }, []);

  const handleModelExported = useCallback(
    (imposterBase64, modelBase64) => {
      if (typeof getValues === 'function' && typeof setValue === 'function') {
        const droppedFile = getValues(fileInputName);
        if (!droppedFile) {
          return;
        }
        const droppedFileName = exportedModelFileName(droppedFile);

        const modelFile = convertFileFromBase64(modelBase64, droppedFileName);
        const imposterFile = convertFileFromBase64(imposterBase64, `imposter_${droppedFileName}`);

        setValue(fileInputName, modelFile);
        if (imposterInputName) {
          setValue(imposterInputName, modelFile);
        }

        if (typeof onModelExported === 'function') {
          onModelExported(imposterFile, modelFile);
        }
      }
    },
    [getValues, fileInputName, setValue, imposterInputName, onModelExported],
  );

  const handleThumbnailExported = useCallback(
    (imgBase64) => {
      if (typeof getValues === 'function') {
        const droppedFile = getValues(fileInputName);
        if (!droppedFile) {
          return;
        }
        const thumbnailFileName = exportedThumbnailFileName(droppedFile);

        const thumbnailFile = convertFileFromBase64(imgBase64, thumbnailFileName);

        if (typeof onThumbnailExported === 'function') {
          onThumbnailExported(thumbnailFile);
        }
      }
    },
    [fileInputName, getValues, onThumbnailExported],
  );

  return (
    <ArtFormModelDropzone
      classNames={dropzoneStyles}
      urlInputName={urlInputName}
      fileInputName={fileInputName}
      errorMessage={errorMessage}
      isRequired={isRequired}
      mimeTypes={mimeTypes}
      maxFileSize={maxFileSize}
      placeholder={placeholder}
      shouldActivateOnClick={shouldActivateOnClick}
      shouldDisplayFilePills={shouldPillsBeDisplayed}
      onDrop={handleChange}
      onRemove={handleRemove}
    >
      <UnityInspector
        classNames={{ wrapper: cx(classes.inspectorWrapper, classNames.inspectorWrapper) }}
        modelSrc={modelSrc}
        onInspectorReady={handleInspectorReady}
        onModelReady={handleModelReady}
        onModelFailed={handleModelFailed}
        onModelExported={handleModelExported}
        onThumbnailExported={handleThumbnailExported}
      />
    </ArtFormModelDropzone>
  );
};

ModelDropzone.propTypes = {
  classNames: PropTypes.shape({
    autoCropperWrapper: PropTypes.string,
    dropzoneInner: PropTypes.string,
    dropzoneRoot: PropTypes.string,
    errorMessage: PropTypes.string,
    inspectorWrapper: PropTypes.string,
    pill: PropTypes.string,
    pillsContainers: PropTypes.string,
    placeholder: PropTypes.string,
    previewContainer: PropTypes.string,
  }),
  errorMessage: PropTypes.node,
  fileInputName: PropTypes.string,
  imposterInputName: PropTypes.string,
  isRequired: PropTypes.bool,
  maxFileSize: PropTypes.number,
  mimeTypes: PropTypes.any,
  onInspectorReady: PropTypes.func,
  onModelExported: PropTypes.func,
  onThumbnailExported: PropTypes.func,
  placeholder: PropTypes.node,
  shouldActivateOnClick: PropTypes.bool,
  shouldDisplayFilePills: PropTypes.bool,
  urlInputName: PropTypes.string,
};

export default ModelDropzone;
