import authorization from './services/authorization';
import editor from './unityPlayers/editor';
import viewer from './unityPlayers/viewer';
import inspector from './unityPlayers/inspector';
import fileManagement from './services/fileManagement';
import sceneManagement from './services/sceneManagement';
import assetManagement from './services/assetManagement';
import userManagement from './services/userManagement';
import socialInteraction from './services/socialInteraction';
import storyTelling from './services/storyTelling';
import communicationInteractionService from './services/communicationInteraction';
import readyPlayerMe from './third-party/readyPlayerMe';

const env = process.env.NODE_ENV;

const apis = {
  keycloak: authorization[env],
  editor: {
    loader: editor.loader[env],
    data: editor.data[env],
    framework: editor.framework[env],
    wasm: editor.wasm[env],
    streamingAssets: editor.streamingAssets[env],
  },
  fileManagement: (params) => fileManagement(params),
  inspector: {
    loader: inspector.loader[env],
    data: inspector.data[env],
    framework: inspector.framework[env],
    wasm: inspector.wasm[env],
    streamingAssets: inspector.streamingAssets[env],
  },
  viewer: {
    loader: viewer.loader[env],
    data: viewer.data[env],
    framework: viewer.framework[env],
    wasm: viewer.wasm[env],
    streamingAssets: viewer.streamingAssets[env],
  },
  assetManagement,
  storyTelling,
  sceneManagement,
  socialInteraction,
  communicationInteractionService,
  userManagement,
  readyPlayerMe,
};

export default apis;
