const BASE_PATH = '/api/userService';

export const DISTRICT_PATH = `${BASE_PATH}/district`;
export const USER_PATH = `${BASE_PATH}/user`;

const userManagement = {
  districts: DISTRICT_PATH,
  district: (id) => `${DISTRICT_PATH}/${id}`,
  districtFull: (id) => `${DISTRICT_PATH}/${id}/full`,
  districtInfoAuth: `${DISTRICT_PATH}/info/auth`,
  districtInfo: `${DISTRICT_PATH}/info`,
  districtAdmin: (id) => `${DISTRICT_PATH}/${id}/admin`,
  myDistricts: `${DISTRICT_PATH}/my`,
  myDistrictsAsMember: `${DISTRICT_PATH}/me`,
  districtMembers: (id) => `${DISTRICT_PATH}/${id}/members`,
  users: USER_PATH,
  user: (id) => `${USER_PATH}/${id}`,
  userSearch: `${USER_PATH}/search`,
};

export default userManagement;
