import { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { filesUrlsPropTypes } from '@artsteps/types';
import { Unity, useUnityContext } from 'react-unity-webgl';
import useUnityEventListener from './useUnityEventListener';

const UnityContext = createContext();

const UnityProvider = ({ children, filesUrls }) => {
  const { isLoaded, unityProvider, sendMessage, addEventListener, removeEventListener } =
    useUnityContext({
      codeUrl: filesUrls.codeUrl,
      dataUrl: filesUrls.dataUrl,
      frameworkUrl: filesUrls.frameworkUrl,
      loaderUrl: filesUrls.loaderUrl,
      streamingAssetsUrl: filesUrls.streamingAssetsUrl,
    });

  const value = useMemo(
    () => ({
      isLoaded,
      sendMessage,
      addEventListener,
      removeEventListener,
      Unity,
      unityProvider,
    }),
    [isLoaded, sendMessage, unityProvider, addEventListener, removeEventListener],
  );

  return <UnityContext.Provider value={value}>{children}</UnityContext.Provider>;
};

const useUnity = () => {
  const context = useContext(UnityContext);
  if (!context) {
    throw new Error('useUnity must be used within a UnityProvider');
  }

  return context;
};

UnityProvider.propTypes = {
  children: PropTypes.node,
  filesUrls: filesUrlsPropTypes,
};

export { UnityProvider, useUnity, useUnityEventListener };
