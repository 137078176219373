import { Route, Routes } from 'react-router-dom';
import { ErrorFallback } from '@artsteps/components';
import routes from '../../server/endpoints/routes';
import HomePage from '../../pages/home';
import Editor from '../../pages/editor';
import Explore from '../../pages/explore';
import ProfilePage from '../../pages/profile';
import Viewer from '../../pages/viewer';
import AccountPage from '../../pages/account';
import DistrictPage from '../../pages/district';
import UnityUploaderDevPage from '../../pages/dev/unity-uploader/UnityUploaderDevPage';

const Content = (props) => (
  <Routes>
    <Route exact path={routes.HOME} element={<HomePage {...props} />} />
    <Route exact path={routes.EXPLORE} element={<Explore {...props} />} />
    <Route exact path={`${routes.EDITOR}/:sceneId`} element={<Editor {...props} />} />
    <Route exact path={routes.EDITOR} element={<Editor {...props} />} />
    <Route exact path={routes.PROFILE} element={<ProfilePage {...props} />} />
    <Route exact path={`${routes.VIEWER}/:sceneId`} element={<Viewer {...props} />} />
    <Route exact path={`${routes.USER}/:userId`} element={<AccountPage {...props} />} />
    <Route exact path={`${routes.USER}/:userId/:category`} element={<AccountPage {...props} />} />
    <Route
      exact
      path={`${routes.USER}/:userId/:category/:subcategory`}
      element={<AccountPage {...props} />}
    />
    <Route exact path={`${routes.DISTRICT}/:districtId`} element={<DistrictPage {...props} />} />
    <Route
      exact
      path={`${routes.DISTRICT}/:districtId/:category`}
      element={<DistrictPage {...props} />}
    />
    <Route
      exact
      path={`${routes.DISTRICT}/:districtId/:category/:subcategory`}
      element={<DistrictPage {...props} />}
    />
    <Route exact path={routes.DEV_UNITY_UPLOADER} element={<UnityUploaderDevPage />} />
    <Route path="*" element={<ErrorFallback message="Page not found" isPageNotFound />} />
  </Routes>
);

export default Content;
