import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createStyles, getStylesRef } from '@mantine/core';
import { Button, Icon, Logo } from '@artsteps/components';
import { useTranslation } from '../../infrastructure/translation/TranslationProvider';
import routes from '../../server/endpoints/routes';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  logo: {
    marginTop: '-10px',
  },
  links: {
    color: theme.colors.typography[1],
    fontWeight: '500',
    letterSpacing: '0.85px',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  explore: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '-6px',
      left: '0',
      width: '100%',
      height: '4px',
      backgroundColor: theme.colors.typography[1],
      opacity: 0,
      transition: ' transform 0.3s ease, opacity 0.3s ease',
    },
    '&:hover::before, &:active::before': {
      opacity: 1,
    },
    '&:active::before': {
      backgroundColor: theme.colors.artsteps[3],
    },
  },
  learningCenter: {
    display: 'flex',
    alignItems: 'center',
    transition: '0.3s ease',
    '&:hover': {
      transform: 'translateX(-4px)',
      [`& .${getStylesRef('learningCenterTooltip')}`]: {
        opacity: 1,
      },
    },
    position: 'relative',
  },
  learningCenterTooltip: {
    ref: getStylesRef('learningCenterTooltip'),
    position: 'absolute',
    bottom: `-${2 * theme.fontSizes.md}px`,
    left: 0,
    opacity: 0,
    transition: 'opacity 0.3s ease',
    fontSize: '14px',
    lineHeight: `${theme.fontSizes.md}px`,
    textShadow: '0px 0px 5px #00000066',
  },
}));

const LeftHeaderSection = ({
  classNames = {},
  hideOptions = {
    shouldHideCreateSpace: false,
    shouldHideExplore: false,
    shouldHideLearningCenter: false,
    shouldHideLogo: false,
  },
  shouldShrinkLogo,
}) => {
  const { classes, cx, theme } = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isInsideEditor = pathname.includes(routes.EDITOR);

  const { shouldHideCreateSpace, shouldHideExplore, shouldHideLearningCenter, shouldHideLogo } =
    hideOptions;

  return (
    <div className={cx(classes.root, classNames.root)}>
      {!shouldHideLogo && (
        <Logo
          className={cx(classes.logo, classNames.logo)}
          isWhite={!isInsideEditor}
          href={routes.HOME}
          shouldShrink={shouldShrinkLogo}
        />
      )}
      {!shouldHideCreateSpace && (
        <Button href={routes.EDITOR} label={t('Menu.Button.CreateSpace')} variant="gradient" />
      )}
      {!shouldHideExplore && (
        <Link
          className={cx(classes.links, classes.explore, classNames.explore)}
          to={routes.EXPLORE}
        >
          {t('Menu.Links.Explore')}
        </Link>
      )}
      {!shouldHideLearningCenter && (
        <Link
          className={cx(classes.links, classes.learningCenter, classNames.learningCenter)}
          to={routes.EXPLORE}
        >
          <Icon
            classNames={classes.learningCenterIcon}
            color={theme.colors.typography[1]}
            size="24px"
            id="learning-center"
          />
          <div className={classes.learningCenterTooltip}>Learning Center</div>
        </Link>
      )}
    </div>
  );
};

LeftHeaderSection.propTypes = {
  classNames: PropTypes.shape({
    explore: PropTypes.string,
    learnginCenter: PropTypes.string,
    logo: PropTypes.string,
    root: PropTypes.string,
  }),
  hideOptions: PropTypes.shape({
    shouldHideCreateSpace: PropTypes.bool,
    shouldHideExplore: PropTypes.bool,
    shouldHideLearningCenter: PropTypes.bool,
    shouldHideLogo: PropTypes.bool,
  }),
  shouldShrinkLogo: PropTypes.bool,
};

export default LeftHeaderSection;
