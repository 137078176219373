import PropTypes from 'prop-types';
var userDataPropTypes = PropTypes.shape({
  active: PropTypes.bool,
  avatar: PropTypes.string,
  bio: PropTypes.string,
  birthday: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  coverPhoto: PropTypes.string,
  email: PropTypes.string,
  gender: PropTypes.string,
  generalRole: PropTypes.string,
  lastLogin: PropTypes.string,
  loginProvider: PropTypes.string,
  name: PropTypes.string,
  numOfFollowers: PropTypes.number,
  numOfFollowing: PropTypes.number,
  numPublishedSpaces: PropTypes.number,
  occupation: PropTypes.string,
  profilePicture: PropTypes.string,
  signedUp: PropTypes.string,
  socialMedia: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string
  })),
  userId: PropTypes.string,
  username: PropTypes.string,
  website: PropTypes.string
});
export default userDataPropTypes;