import { px } from '@mantine/core';
var predefinedSizes = {
  xs: 10,
  sm: 12,
  md: 16,
  lg: 18,
  xl: 22
};
export var calculateToggleSize = function calculateToggleSize(size) {
  if (size in predefinedSizes) {
    return predefinedSizes[size];
  }
  if (typeof size === 'number') {
    return size;
  }
  if (typeof size === 'string' && size.endsWith('rem')) {
    return px(size);
  }
  return Number(size);
};
export var adjustSize = function adjustSize(size) {
  var rounded = Math.ceil(size);
  return rounded % 2 === 0 ? rounded : rounded + 1;
};