const BASE_PATH = '/api/socialInteractionService';

const socialInteraction = {
  sceneLikes: (sceneId) => `${BASE_PATH}/scenelike/scene/${sceneId}`,
  likeScene: `${BASE_PATH}/scenelike`,
  unlikeScene: (sceneId) => `${BASE_PATH}/scenelike/scene/${sceneId}`,
  userFavourites: (userId) => `${BASE_PATH}/scenelike/user/${userId}`,
  userFollowing: (userId) => `${BASE_PATH}/userfollow/user/${userId}`,
  sceneComments: (sceneId) => `${BASE_PATH}/scenecomment/scene/${sceneId}`,
  sceneAddComment: `${BASE_PATH}/scenecomment`,
  comment: (id) => `${BASE_PATH}/scenecomment/${id}`,
  districtDoesFollow: (districtId) =>
    `${BASE_PATH}/districtfollow/district/${districtId}/doesfollow`,
  followDistrict: `${BASE_PATH}/districtfollow`,
  unfollowDistrict: (districtId) => `${BASE_PATH}/districtfollow/district/${districtId}`,
  followedDistricts: (userId) => `${BASE_PATH}/districtfollow/user/${userId}`,
};

export default socialInteraction;
