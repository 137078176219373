const routes = {
  HOME: '/',
  EXHIBITION: '/exhibition',
  EXPLORE: '/explore',
  EDITOR: '/curate',
  PROFILE: '/profile',
  VIEWER: '/viewer',
  USER: '/user',
  DISTRICT: '/district',
  DEV_UNITY_UPLOADER: '/dev-unity-uploader',
};

export default routes;
