import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { userDataPropTypes } from '@artsteps/types';

const UserContext = createContext();

const UserDataProvider = ({ isLoading, userData, setUserData, userId, children }) => {
  const isUserMe = useCallback((otherUserId) => otherUserId === userId, [userId]);

  const value = useMemo(
    () => ({
      isUserMe,
      loading: isLoading,
      setUserData,
      userData,
      userId,
    }),
    [isUserMe, isLoading, setUserData, userData, userId],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserContext must be used within a UserDataProvider');
  }

  return context;
};

UserDataProvider.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  setUserData: PropTypes.func,
  userData: userDataPropTypes,
  userId: PropTypes.string,
};

export { UserDataProvider, useUserContext };
