import PropTypes from 'prop-types';
var guidedTourStoryPointPropTypes = PropTypes.shape({
  audioUri: PropTypes.string,
  bounds: PropTypes.string,
  boundsCenter: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
  guidedTourId: PropTypes.number,
  guidedTourPointId: PropTypes.number,
  instanceTag: PropTypes.string,
  order: PropTypes.number,
  position: PropTypes.string,
  rotation: PropTypes.string,
  scale: PropTypes.string,
  title: PropTypes.string
});
export default guidedTourStoryPointPropTypes;