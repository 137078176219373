export const exportedModelFileName = (glbFile) => glbFile?.name;

export const exportedThumbnailFileName = (glbFile) => {
  const modelFileName = exportedModelFileName(glbFile);

  let thumbnailFileName =
    modelFileName.substring(0, modelFileName.lastIndexOf('.')) || modelFileName;
  thumbnailFileName += '.png';

  return thumbnailFileName;
};
