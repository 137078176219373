export const asyncReadFile = (file) =>
  new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', (event) => {
      const text = event.target.result;
      resolve(text);
    });
    fileReader.readAsText(file);
  });

/**
 * Takes in a file, creates the blob url and returns only is hash
 * @param {File} file
 * @returns file's blobUrl's hash
 */
export const extractHashFromBlobUrl = (file) => {
  const fileBlobUrl = URL.createObjectURL(file);
  const urlOrigin = `blob:${window.location.origin}/`;
  const blobHashonly = fileBlobUrl.replace(urlOrigin, '');
  return `${blobHashonly}#`;
};

/**
 * Takes a parent file and the relative files that are mentioned inside it.
 * Create a Blob URL from each relative file and keeps only the hash part of it
 * Then replaces the parent file's relative paths with the hashes of those relative files
 * @param {File} parentFile the initial parent File, use cases are OBJ or MTL
 * @param {Array[File]} relativeFiles the relative files, mentioned inside the parent file, to be replaced
 */
export const replaceFilePathsWithBlobs = async (parentFile, relativeFiles) => {
  const parentFileContent = await asyncReadFile(parentFile);

  const updatedParentFile = relativeFiles.reduce((acc, file) => {
    const blobHash = extractHashFromBlobUrl(file);
    const filePath = ` .*${file.name}`;
    return acc.replaceAll(new RegExp(filePath, 'g'), ` ${blobHash}`);
  }, parentFileContent);

  return new File([updatedParentFile], parentFile.name);
};

const groupFiles = (droppedFiles) => {
  const groupedFiles = {
    glb: [],
    obj: [],
    mtl: [],
    rest: [],
  };

  droppedFiles.forEach((file) => {
    if (file.name.endsWith('.glb')) {
      groupedFiles.glb.push(file);
    } else if (file.name.endsWith('.obj')) {
      groupedFiles.obj.push(file);
    } else if (file.name.endsWith('.mtl')) {
      groupedFiles.mtl.push(file);
    } else {
      groupedFiles.rest.push(file);
    }
  });

  return groupedFiles;
};

export const validator = (droppedFiles, setFiles) => {
  const groupedFiles = groupFiles(droppedFiles);

  if (groupedFiles.glb.length > 1 || groupedFiles.obj > 1) {
    setFiles(null);
    throw new Error("Can't have more than 1 3D model files");
  }

  return null;
};

export const handleDropzoneDroppedFiles = async (droppedFiles) => {
  const {
    glb: glbFiles,
    obj: objFiles,
    mtl: mtlFiles,
    rest: textureFiles,
  } = groupFiles(droppedFiles);

  if (glbFiles.length === 1) {
    return glbFiles[0];
  }

  if (objFiles.length === 1) {
    const objFileContent = await asyncReadFile(objFiles[0]);

    const includedMtlFiles = mtlFiles.filter((mtlf) => objFileContent.includes(mtlf.name));

    if (includedMtlFiles.length === 0) {
      return objFiles[0];
    }

    const edittedMtlFiles = await Promise.all(
      includedMtlFiles.map(async (file) => await replaceFilePathsWithBlobs(file, textureFiles)),
    );
    const edittedObjFile = await replaceFilePathsWithBlobs(objFiles[0], edittedMtlFiles);

    return edittedObjFile;
  }

  return null;
};
