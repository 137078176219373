import { useEffect } from 'react';
import { useOidc } from '@axa-fr/react-oidc';
import UnthemedLoadingScreen from './UnthemedLoadingScreen';

const SessionLost = () => {
  const { login } = useOidc();

  useEffect(() => {
    login();
  });

  return <UnthemedLoadingScreen />;
};

export default SessionLost;
