import { useLocation } from 'react-router-dom';
import { createStyles } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Header, HeaderSection, screenSizes } from '@artsteps/components';
import routes from '../../server/endpoints/routes';
import LeftHeaderSection from '../../common/header/LeftHeaderSection';
import UserSection from '../../common/header/user/UserSection';

const useStyles = createStyles((_, { isInside3D }) => ({
  header: {
    position: isInside3D ? 'fixed' : 'relative',
  },
  createSpaceButton: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

const HeaderComponent = () => {
  const { pathname } = useLocation();
  const isInside3D = pathname.includes(routes.EDITOR) || pathname.includes(routes.VIEWER);
  const { classes } = useStyles({ isInside3D });
  const shouldHideCreateSpace = useMediaQuery(`(max-width: ${screenSizes.small.max})`);

  if (isInside3D) {
    return null;
  }

  return (
    <Header className={classes.header}>
      <HeaderSection>
        <LeftHeaderSection hideOptions={{ shouldHideCreateSpace }} />
      </HeaderSection>
      <HeaderSection>
        <UserSection />
      </HeaderSection>
    </Header>
  );
};

export default HeaderComponent;
