import { useEffect } from 'react';
import { useUnity } from './UnityProvider';

const useUnityEventListener = (
  { eventName, callback, shouldAddEventListener = true },
  dependencyArray = [],
) => {
  const { addEventListener, removeEventListener, isLoaded } = useUnity();

  useEffect(
    () => {
      if (typeof callback === 'function' && isLoaded && shouldAddEventListener) {
        addEventListener(eventName, callback);
      }

      return () => {
        removeEventListener(eventName, callback);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      eventName,
      isLoaded,
      addEventListener,
      removeEventListener,
      shouldAddEventListener,
      ...dependencyArray, // eslint-disable-line react-hooks/exhaustive-deps
    ],
  );
};

export default useUnityEventListener;
