import screenSizes from '../../utils/screenSizes';
var defaultTheme = {
  breakpoints: {
    xs: screenSizes.xsmall.max,
    sm: screenSizes.small.max,
    md: screenSizes.medium.max,
    lg: screenSizes.large.max,
    xl: screenSizes.xlarge.max
  },
  colorScheme: 'light',
  colors: {
    artsteps: ['#DFF3EF', '#B0E0D6', '#7CCDBB', '#43B9A0', '#01A98D', '#00997A', '#008C6E', '#007C5F', '#006C51', '#005035'],
    payment: ['#e7c423', '#e7c423', '#e7c423', '#e7c423', '#e7c423', '#e7c423', '#e7c423', '#e7c423', '#e7c423', '#e7c423'],
    typography: ['#FFFFFF', '#FFFFFFDE', '#FFFFFF9E', '#FFFFFF61', '#00000061', '#0000009E', '#000000DE', '#000000', '#43B9A0', '#9C4353'],
    surfaces: ['#FFFFFF', '#FFFFFFDE',
    // 87%
    '#DEDEDFE6',
    // 90%
    '#FFFFFFBD',
    // 74%
    '#FFFFFF61',
    // 38%
    '#00000061',
    // 38%
    '#0000009E',
    // 62%
    '#000000DE',
    // 87%
    '#00000033',
    // 20%
    '#9C4353']
  },
  defaultRadius: 'xl',
  fontFamily: 'Ubuntu, sans-serif',
  loader: 'oval',
  other: {
    typographyColor: function typographyColor(theme) {
      return theme.colorScheme === 'dark' ? theme.colors.typography[2] : theme.colors.typography[4];
    }
  },
  fontSizes: {
    xs: 10,
    sm: 12,
    md: 16,
    lg: 18,
    xl: 22
  },
  globalStyles: function globalStyles(theme) {
    return {
      '::-webkit-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '::-webkit-scrollbar-thumb': {
        backgroundClip: 'padding-box',
        backgroundColor: theme.colors.surfaces[6],
        borderRadius: '16px'
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: '#0000004D'
      },
      html: {
        scrollbarWidth: 'auto',
        scrollbarColor: theme.colors.surfaces[6] + " " + theme.colors.surfaces[2],
        overscrollBehavior: 'contain',
        scrollBehavior: 'smooth'
      }
    };
  },
  headings: {
    sizes: {
      h1: {
        fontSize: 80
      },
      h2: {
        fontSize: 66
      },
      h3: {
        fontSize: 56
      },
      h4: {
        fontSize: 44
      },
      h5: {
        fontSize: 32
      }
    }
  },
  primaryColor: 'artsteps',
  primaryShade: {
    light: '3',
    dark: '2'
  },
  shadows: {
    active: function active(theme) {
      return "0 0 7px 3px " + theme.fn.primaryColor() + "BB";
    },
    gradientButton: function gradientButton(theme) {
      return "0px 0px 10px " + theme.colors.artsteps[7];
    },
    basic: '0px 2px 5px #00000066',
    md: '1px 1px 3px rgba(0, 0, 0, .25)',
    xl: '5px 5px 3px rgba(0, 0, 0, .25)',
    text: '0px 0px 5px #00000066'
  },
  components: {
    Input: {
      styles: function styles(theme) {
        return {
          input: {
            '&[data-invalid]': {
              borderColor: theme.colors.typography[9],
              color: theme.colors.typography[9],
              '&::placeholder': {
                color: theme.colors.typography[9]
              }
            }
          }
        };
      }
    }
  }
};
export default defaultTheme;