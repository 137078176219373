import { BrowserRouter as Router } from 'react-router-dom';
import Header from './Header';
import Content from './Routes';

const Main = () => (
  <Router>
    <Header />
    <Content />
  </Router>
);

export default Main;
