var NEW_GROUP_MESSAGE_TIME_THRESHOLD = 10;
var updateMessageGroups = function updateMessageGroups(newMessage, messageGroups) {
  if (messageGroups === void 0) {
    messageGroups = [];
  }
  var newMessageUser = newMessage.user,
    newMessageTime = newMessage.time,
    newMessageAlignment = newMessage.alignment,
    newMessageId = newMessage.messageId;
  var numberOfGroups = messageGroups.length;

  // If groups exist, check if newMessage should be added to the last group
  if (numberOfGroups) {
    var lastGroup = messageGroups[numberOfGroups - 1];
    var _lastGroup$messages = lastGroup.messages[lastGroup.messages.length - 1],
      user = _lastGroup$messages.user,
      time = _lastGroup$messages.time;
    if (user.id !== newMessageUser.id || Math.abs(time - newMessageTime) > NEW_GROUP_MESSAGE_TIME_THRESHOLD) {
      return [].concat(messageGroups, [{
        messages: [newMessage],
        user: newMessageUser,
        alignment: newMessageAlignment,
        groupId: newMessageId
      }]);
    }
    var updatedLastGroup = Object.assign({}, lastGroup, {
      messages: [].concat(lastGroup.messages, [newMessage])
    });
    var result = [].concat(messageGroups);
    result[numberOfGroups - 1] = updatedLastGroup;
    return result;
  }
  return [].concat(messageGroups, [{
    messages: [newMessage],
    user: newMessageUser,
    alignment: newMessageAlignment,
    groupId: newMessageId
  }]);
};
export default updateMessageGroups;