const BASE_PATH = '/api/sceneService';

export const SCENE_PATH = `${BASE_PATH}/scene`;
export const CATEGORY_PATH = `${BASE_PATH}/category`;

const sceneManagement = {
  categories: CATEGORY_PATH,
  category: (id) => `${CATEGORY_PATH}/${id}`,
  scenes: SCENE_PATH,
  sceneCreate: SCENE_PATH,
  scenePatch: (id) => `${SCENE_PATH}/${id}`,
  scenePublish: (id) => `${SCENE_PATH}/${id}/publish/public`,
  scenePublished: (id) => `${SCENE_PATH}/publishedScene/${id}`,
  scenesPublished: `${SCENE_PATH}/publishedScene`,
  scenesPublishedAuth: `${SCENE_PATH}/publishedScene/auth`,
  scene: (id) => `${SCENE_PATH}/${id}`,
  sceneUnity: (id) => `${SCENE_PATH}/${id}/unity`,
  sceneSearch: `${SCENE_PATH}/search`,
};

export default sceneManagement;
