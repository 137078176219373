import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import { NavLink } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  link: {
    margin: '7px 0px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.colors.typography[6],
    display: 'block',
  },
}));

const DrawerText = ({ onClick, children, className, to }) => {
  const { classes, cx } = useStyles();

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <NavLink className={cx(classes.link, className)} onClick={handleClick} {...(to ? { to } : {})}>
      {children}
    </NavLink>
  );
};

export default DrawerText;

DrawerText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
};
