/* eslint-disable camelcase */
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import format from 'date-fns/format';
import { enGB } from 'date-fns/locale';
var parseDateString = function parseDateString(dateString) {
  var date = dateString;
  if (!isValid(date)) {
    date = parseISO(date);
  }
  return date;
};
export var getCurrentTime_HH_MM = function getCurrentTime_HH_MM() {
  var currentDate = new Date();
  var hours = currentDate.getHours();
  var minutes = currentDate.getMinutes();
  var hhmm = "" + (hours < 10 ? '0' : '') + hours + ":" + (minutes < 10 ? '0' : '') + minutes;
  return hhmm;
};
export var formatDurationTime = function formatDurationTime(milliSeconds) {
  if (milliSeconds === void 0) {
    milliSeconds = 0;
  }
  var interval = typeof milliSeconds === 'string' ? Number(milliSeconds) : milliSeconds;
  var duration = intervalToDuration({
    start: 0,
    end: interval
  });
  var zeroPad = function zeroPad(num) {
    return String(num).padStart(2, '0');
  };
  var formatted = formatDuration(duration, {
    format: ['minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: function formatDistance(_token, count) {
        return zeroPad(count);
      }
    }
  });
  return formatted;
};
export var formatTime_HH_MM = function formatTime_HH_MM(dateString) {
  var date = parseDateString(dateString);
  if (!isValid(date)) {
    return null;
  }
  return format(date, 'HH:mm');
};
export var formatTime_HH_MM_SS = function formatTime_HH_MM_SS(dateString) {
  var date = parseDateString(dateString);
  if (!isValid(date)) {
    return null;
  }
  return format(date, 'HH:mm:ss');
};
export var formatTime_HH_MM_SS_SSS = function formatTime_HH_MM_SS_SSS(dateString) {
  var date = parseDateString(dateString);
  if (!isValid(date)) {
    return null;
  }
  return format(date, 'HH:mm:ss.SSS');
};
export var formatToLocaleDate = function formatToLocaleDate(dateString, styleFormat, localeFormat) {
  if (styleFormat === void 0) {
    styleFormat = 'P';
  }
  if (localeFormat === void 0) {
    localeFormat = enGB;
  }
  var date = parseDateString(dateString);
  if (!isValid(date)) {
    return null;
  }
  var formattedDate = format(date, styleFormat, {
    locale: localeFormat
  });
  return formattedDate;
};
export var getTimeRelativeToToday = function getTimeRelativeToToday(dateString) {
  var date = parseDateString(dateString);
  if (!isValid(date)) {
    return null;
  }
  var relativeTime = formatDistanceToNowStrict(date, {
    addSuffix: true
  });
  return relativeTime;
};
export default {
  formatDurationTime: formatDurationTime,
  formatToLocaleDate: formatToLocaleDate,
  formatTime_HH_MM: formatTime_HH_MM,
  formatTime_HH_MM_SS: formatTime_HH_MM_SS,
  formatTime_HH_MM_SS_SSS: formatTime_HH_MM_SS_SSS,
  getCurrentTime_HH_MM: getCurrentTime_HH_MM,
  getTimeRelativeToToday: getTimeRelativeToToday
};