import { useCallback, useEffect, useState } from 'react';
var useScroll = function useScroll(_ref) {
  var elementRef = _ref.elementRef;
  var _useState = useState({
      height: 0,
      top: 0,
      bottom: 0
    }),
    scrollMetrics = _useState[0],
    setScrollMetrics = _useState[1];
  var _useState2 = useState(false),
    isScrolling = _useState2[0],
    setIsScrolling = _useState2[1];
  var scrollToStart = useCallback(function (_ref2) {
    var _ref2$start = _ref2.start,
      start = _ref2$start === void 0 ? 'bottom' : _ref2$start,
      onScrollDone = _ref2.onScrollDone;
    if (isScrolling) {
      return;
    }
    var top = start === 'top' ? 0 : scrollMetrics.height + 2000;
    elementRef == null ? void 0 : elementRef.current.scroll({
      top: top,
      behavior: 'smooth'
    });
    if (typeof onScrollDone === 'function') {
      onScrollDone();
    }
  }, [elementRef, scrollMetrics, isScrolling]);
  var handleScroll = useCallback(function (_ref3) {
    var _ref3$target = _ref3.target,
      scrollHeight = _ref3$target.scrollHeight,
      scrollTop = _ref3$target.scrollTop,
      clientHeight = _ref3$target.clientHeight;
    clearTimeout(window.scrollEndTimer);
    if (!isScrolling) {
      setIsScrolling(true);
    }
    window.scrollEndTimer = setTimeout(function () {
      setScrollMetrics({
        height: scrollHeight,
        top: scrollTop,
        bottom: scrollHeight - scrollTop - clientHeight
      });
      setIsScrolling(false);
    }, 100);
  }, [isScrolling]);
  useEffect(function () {
    var wrapperElement = elementRef == null ? void 0 : elementRef.current;
    wrapperElement.addEventListener('scroll', handleScroll);
    return function () {
      return wrapperElement.removeEventListener('scroll', handleScroll);
    };
  }, [elementRef, handleScroll]);
  return {
    isScrolling: isScrolling,
    scrollToStart: scrollToStart,
    scrollHeight: scrollMetrics.height,
    scrollTop: scrollMetrics.top,
    scrollBottom: scrollMetrics.bottom
  };
};
export default useScroll;