import { useOidc } from '@axa-fr/react-oidc';
import { useTranslation } from '../../../infrastructure/translation/TranslationProvider';
import routes from '../../../server/endpoints/routes';

const TRANSLATION_BASE = 'Header.UserProfileMenu';

const useMenuItems = (userId) => {
  const { logout } = useOidc();
  const { t } = useTranslation();
  const BASE_ACCOUNT_ROUTE = `${routes.USER}/${userId}/`;

  const mainMenuItems = [
    {
      label: t(`${TRANSLATION_BASE}.Avatar`),
      to: `${BASE_ACCOUNT_ROUTE}`,
    },
    {
      label: t(`${TRANSLATION_BASE}.Spaces`),
      to: `${BASE_ACCOUNT_ROUTE}spaces`,
    },
    {
      label: t(`${TRANSLATION_BASE}.Wallet`),
      to: `${BASE_ACCOUNT_ROUTE}`,
    },
    {
      label: t(`${TRANSLATION_BASE}.Districts`),
      to: `${BASE_ACCOUNT_ROUTE}districts`,
    },
    {
      label: t(`${TRANSLATION_BASE}.Groups`),
      to: `${BASE_ACCOUNT_ROUTE}groups`,
    },
    {
      label: t(`${TRANSLATION_BASE}.MyLibrary`),
      to: `${BASE_ACCOUNT_ROUTE}my_content`,
    },
    {
      label: t(`${TRANSLATION_BASE}.Favorites`),
      to: `${BASE_ACCOUNT_ROUTE}favourites`,
    },
    {
      label: t(`${TRANSLATION_BASE}.History`),
      to: `${BASE_ACCOUNT_ROUTE}history`,
    },
  ];

  const bottomMenuItems = [
    {
      label: t(`${TRANSLATION_BASE}.Settings`),
      to: `${BASE_ACCOUNT_ROUTE}`,
    },
    {
      label: t(`${TRANSLATION_BASE}.Language`),
      to: `${BASE_ACCOUNT_ROUTE}`,
    },
    {
      label: t(`${TRANSLATION_BASE}.LogOut`),
      onClick: () => logout(),
    },
  ];

  return { mainMenuItems, bottomMenuItems };
};

export default useMenuItems;
