import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import { Loader } from '@artsteps/components';
import {
  useUnity,
  useUnityEventListener,
} from '../../../infrastructure/unity-provider/UnityProvider';
import { unityBrokers } from '../../../infrastructure/unity-provider/unity-brokers/brokers';

const useStyles = createStyles(() => ({
  wrapper: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  loader: {
    width: '100%',
    height: '100%',
  },
  inspector: {
    height: '100%',
    width: '100%',
  },
}));

const UnityInspector = ({
  classNames = {},
  modelSrc,
  onInspectorReady,
  onModelExported,
  onThumbnailExported,
  onModelReady,
  onModelFailed,
}) => {
  const { cx, classes } = useStyles();
  const { isLoaded, unityProvider, Unity, sendMessage } = useUnity();
  const [isInspectorReady, setIsInspectorReady] = useState(false);

  useEffect(() => {
    if (isInspectorReady && isLoaded && modelSrc) {
      sendMessage(unityBrokers.inspector, 'ImportModel', modelSrc);
    }
  }, [modelSrc, isLoaded, sendMessage, isInspectorReady]);

  const handleInspectorReady = () => {
    setIsInspectorReady(true);
    if (typeof onInspectorReady === 'function') {
      onInspectorReady();
    }
  };

  const handleModelReady = useCallback(
    (statusString) => {
      const status = JSON.parse(statusString);
      if (status.Success) {
        if (typeof onModelReady === 'function') {
          onModelReady(status.ModelAnimations);
        }
      } else if (typeof onModelFailed === 'function') {
        onModelFailed(status);
      }
    },
    [onModelFailed, onModelReady],
  );

  useUnityEventListener(
    {
      eventName: 'InspectorReady',
      callback: handleInspectorReady,
    },
    [handleInspectorReady],
  );

  useUnityEventListener(
    {
      eventName: 'ModelExported',
      callback: onModelExported,
    },
    [onModelExported],
  );

  useUnityEventListener(
    {
      eventName: 'ThumbnailExported',
      callback: onThumbnailExported,
    },
    [onThumbnailExported],
  );

  useUnityEventListener(
    {
      eventName: 'ModelReady',
      callback: handleModelReady,
    },
    [handleModelReady],
  );

  return (
    <div className={cx(classes.wrapper, classNames.wrapper)}>
      {!isLoaded && (
        <span className={cx(classes.loader, classNames.loader)}>
          <Loader size={60} dataTestId="model-inspector-loader" />
        </span>
      )}
      <Unity
        className={cx(classes.inspector, classNames.inspector)}
        unityProvider={unityProvider}
      />
    </div>
  );
};

UnityInspector.propTypes = {
  classNames: PropTypes.shape({
    inspector: PropTypes.string,
    loader: PropTypes.string,
  }),
  modelSrc: PropTypes.string,
  onInspectorReady: PropTypes.func,
  onModelExported: PropTypes.func,
  onModelFailed: PropTypes.func,
  onModelReady: PropTypes.func,
  onThumbnailExported: PropTypes.func,
};

export default UnityInspector;
