export var maxArrayLength = function maxArrayLength(_ref) {
  var _ref$maxValue = _ref.maxValue,
    maxValue = _ref$maxValue === void 0 ? 10 : _ref$maxValue,
    message = _ref.message;
  return function (value) {
    return ((value == null ? void 0 : value.length) || 0) <= maxValue || message;
  };
};
export var minArrayLength = function minArrayLength(_ref2) {
  var _ref2$minValue = _ref2.minValue,
    minValue = _ref2$minValue === void 0 ? 3 : _ref2$minValue,
    message = _ref2.message;
  return function (value) {
    return ((value == null ? void 0 : value.length) || 0) >= minValue || message;
  };
};
export var customRequiredValidation = function customRequiredValidation(value) {
  if (value == null || (value == null ? void 0 : value.length) < 1 || value === '') {
    return 'This field is required';
  }
  return true;
};